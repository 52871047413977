import { Component, OnInit } from '@angular/core';
// import * as M from 'materialize-css';
declare var $: any;
// import $ from 'jquery';

@Component({
  selector: 'dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.css']
})
export class DropdownMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
    //Initialize dropdown
    // document.addEventListener('DOMContentLoaded', function() {
    //   var elems = document.querySelectorAll('.dropdown-trigger');
    //   var instances = M.Dropdown.init(elems);
    // });

    $('.dropdown-trigger').dropdown();
  }

}
