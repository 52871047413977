import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.css']
})
export class ImageCardComponent implements OnInit {

  cardImgSrc = "../../assets/nature3.jpg";
  cardActionLink = "";
  cardActionLinkTitle = "Go";
  cardTitle = "Text Generator"
  cardContent = "Text Generator is designed to aid developers and web designer generate filler text or placeholder text!";
  
  @Input('dataFromParent') cardDetails = {
    "cardImgSrc" : "",
    "cardActionLink" : "",
    "cardActionLinkTitle" : "",
    "cardTitle" : "",
    "cardContent" : ""
  }

  constructor() { }

  ngOnInit() {
    if(this.cardDetails.cardTitle) {
      this.cardImgSrc = this.cardDetails.cardImgSrc;
      this.cardActionLink = this.cardDetails.cardActionLink;
      this.cardActionLinkTitle = this.cardDetails.cardActionLinkTitle;
      this.cardTitle = this.cardDetails.cardTitle;
      this.cardContent = this.cardDetails.cardContent;
    }
  }

}
