import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'horizontal-card',
  templateUrl: './horizontal-card.component.html',
  styleUrls: ['./horizontal-card.component.css']
})
export class HorizontalCardComponent implements OnInit {

  cardImgSrc = "https://lorempixel.com/100/190/nature";
  cardActionLink = "";
  cardActionLinkTitle = "Go";
  cardTitle = "Text Generator"
  cardContent = "Text Generator is designed to aid developers and web designer generate filler text or placeholder text!";
  
  constructor() { }

  ngOnInit() {
  }

}
