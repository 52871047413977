import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  public dataToChild = {
    "buttonData" : {
      "title" : "Go Live",
      "icon" : "cloud",
      "name" : "textEditorBtn",
      "id" :  "textEditorBtnId",
      "disabled" : false
    },
    "collapsibleData" : {
      "title" : "Text Generator",
      "icon" : "more_vert",
      "body" : "Some details from parent"
    },
    "navigationDrawerData" : {
      "title" : "PlexiText",
      "profileData" : {
        "firstName" : "John",
        "lastName" : "Doe",
        "fullName" : "John Doe",
        "email" : "john@doe.com"
      },
      "menuOptions" : {

      }
    }    
  }
}
