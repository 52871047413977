import { Component, OnInit, Input } from '@angular/core';
// import * as M from 'materialize-css';
declare var $: any;
// import $ from 'jquery';

@Component({
  selector: 'navigation-drawer',
  templateUrl: './navigation-drawer.component.html',
  styleUrls: ['./navigation-drawer.component.css']
})
export class NavigationDrawerComponent implements OnInit {

  companyName = "PlexiText";
  isLoggedIn = false;
  
  profileData = {
    "firstName" : "Unavailable",
    "lastName" : "Unavailable",
    "fullName" : "Unavailable",
    "email" : "Unavailable"
  }

  menuOptions = {
    "primaryMenu" : [
      {
        "title" : "Text Generator",
        "url" : "/text-generator",
        "icon" : "edit"
      }
    ],
    "secondaryMenu" : [      
      {
        "title" : "About",
        "url" : "/about",
        "icon" : "info"
      },
      {
        "title" : "Terms of Use and Privacy",
        "url" : "/terms",
        "icon" : "book"
      }
    ] 
  }
    
  // {
  //   "title" : "Contact",
  //   "url" : "#!",
  //   "icon" : "comment"
  // },
  // ,
  //     {
  //       "title" : "Text Editor",
  //       "url" : "#!",
  //       "icon" : "description"
  //     }
  // ,
  //     {
  //       "title" : "Log Out",
  //       "url" : "#!",
  //       "icon" : "power_settings_new"
  //     }


  @Input() public dataFromParent;

  constructor() {
    
   }

  ngOnInit() {

    //Initialize sidenav
    // document.addEventListener('DOMContentLoaded', function() {
    //   var elems = document.querySelectorAll('.sidenav');
    //   var instances = M.Sidenav.init(elems);
    // });

    $(document).ready(function(){
      $('.sidenav').sidenav();
    });

    if(this.dataFromParent.navigationDrawerData.profileData) {
      this.profileData = this.dataFromParent.navigationDrawerData.profileData;
    }

  }

  closeDrawer() {
    $('.sidenav').sidenav('close');
  }

}
