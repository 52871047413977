import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeMainComponent } from './te-main/te-main.component';
import { TextEditorRoutingModule } from './text-editor-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TextEditorRoutingModule
  ],
  declarations: [TeMainComponent],
  exports: [TeMainComponent]
})
export class TextEditorModule { }
