import { Component, OnInit, Input } from '@angular/core';
declare var $:any;

@Component({
  selector: 'collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.css']
})
export class CollapsibleComponent implements OnInit {

  title = "Unavailable";
  icon = "block";
  body = "Not Found";

  @Input() public dataFromParent;
  //  = {
  //   "collapsibleData": {
  //     "title" : "",
  //     "icon" : "",
  //     "body" : ""
  //   }
  // }

  constructor() { }

  ngOnInit() {

    $(document).ready(function(){
      $('.collapsible').collapsible();
      $('.collapsible').open(0);
    });

    if(this.dataFromParent.collapsibleData) {
      if(this.dataFromParent.collapsibleData.title) {
        this.title = this.dataFromParent.collapsibleData.title;
      }
      if(this.dataFromParent.collapsibleData.icon) {
        this.icon = this.dataFromParent.collapsibleData.icon;
      }
      if(this.dataFromParent.collapsibleData.body) {
        this.body = this.dataFromParent.collapsibleData.body;
      }
    }



  }

}
