import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms'
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule, AngularFirestore } from 'angularfire2/firestore';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationDrawerComponent } from './components/navigation-drawer/navigation-drawer.component';
import { DynamicButtonComponent } from './components/dynamic-button/dynamic-button.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { HorizontalCardComponent } from './components/horizontal-card/horizontal-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { TextGeneratorModule } from './text-generator/text-generator.module';
import { TextEditorModule } from './text-editor/text-editor.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomepageModule } from './homepage/homepage.module';
import { AboutComponent } from './components/about/about.component';
import { TermsComponent } from './components/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationDrawerComponent,
    DynamicButtonComponent,
    DropdownMenuComponent,
    HorizontalCardComponent,
    FooterComponent,
    PageNotFoundComponent,
    AboutComponent,
    TermsComponent 
    
  ],
  imports: [
    BrowserModule,
    TextGeneratorModule,
    TextEditorModule,
    HomepageModule,     
    AngularFireModule.initializeApp(environment.firebase, 'plexitext'),
    AngularFirestoreModule,
    FormsModule,
    AppRoutingModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
