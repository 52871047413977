import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tg-main',
  templateUrl: './tg-main.component.html',
  styleUrls: ['./tg-main.component.css']
})
export class TGMainComponent implements OnInit {

  public collapsible = {
    "collapsibleData" : {
      "title" : "Text Generator",
      "icon" : "settings",
      "body" : "Some details from parent"
    }   
  }

  constructor() { }

  ngOnInit() {
  }

}
