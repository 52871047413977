import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hp-main',
  templateUrl: './hp-main.component.html',
  styleUrls: ['./hp-main.component.css']
})
export class HpMainComponent implements OnInit {

  constructor() { }

  public dataToChild = {
    "buttonData" : {
      "title" : "Go Live",
      "icon" : "cloud",
      "name" : "textEditorBtn",
      "id" :  "textEditorBtnId",
      "disabled" : false
    },
    "collapsibleData" : {
      "title" : "Text Generator",
      "icon" : "more_vert",
      "body" : "Some details from parent"
    },
    "navigationDrawerData" : {
      "title" : "PlexiText",
      "profileData" : {
        "firstName" : "Syean",
        "lastName" : "Wilson",
        "fullName" : "Syean Wilson",
        "email" : "syean15@gmail.com"
      },
      "menuOptions" : {

      }
    }    
  }

  ngOnInit() {
  }

}
