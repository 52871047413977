import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * Should be used in parent like: <dynamic-button (childEvent)="getChildData($event)" [dataFromParent]="dataToChild"></dynamic-button>
 * dataFromParent needs an object in the format:
 * public dataToChild = {
 *   "buttonData" : {
 *      "title" : "Go Live",
 *      "icon" : "cloud",
 *      "name" : "textEditorBtn",
 *      "id" :  "textEditorBtnId",
 *      "disabled" : false
 *    }
 *  }
 */


@Component({
  selector: 'dynamic-button',
  templateUrl: './dynamic-button.component.html',
  styleUrls: ['./dynamic-button.component.css']
})
export class DynamicButtonComponent implements OnInit {

  icon = "arrow_forward";
  title = "Go";
  disabled = false;
  name = "freeBtn";
  id = "freeBtn";
  

  @Input() public dataFromParent;
  @Output() public childEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if(this.dataFromParent) {
      if(this.dataFromParent.buttonData.title) {
        this.title = this.dataFromParent.buttonData.title;
      }  
      if(this.dataFromParent.buttonData.icon) {
        this.icon = this.dataFromParent.buttonData.icon;
      }     
      if(this.dataFromParent.buttonData.disabled) {
        this.disabled = this.dataFromParent.buttonData.disabled;
      } 
      if(this.dataFromParent.buttonData.name) {
        this.name = this.dataFromParent.buttonData.name;
      } 
      if(this.dataFromParent.buttonData.id) {
        this.id = this.dataFromParent.buttonData.id;
      } 
    }
  }

  handleClick(e) {

    this.fireEvent(e); 

  }


  fireEvent(e) { 
    this.childEvent.emit(e);  
  }

}
