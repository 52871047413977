import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'card-holder',
  templateUrl: './card-holder.component.html',
  styleUrls: ['./card-holder.component.css']
})
export class CardHolderComponent implements OnInit {

  features = [{
    "cardImgSrc" : "../../assets/nature3.jpg",
    "cardActionLink" : "/text-generator",
    "cardActionLinkTitle" : "Go",
    "cardTitle" : "Text Generator",
    "cardContent" : "Text Generator is designed to aid developers and web designer generate filler text or placeholder text!",
    "available" : true
  },
  {
    "cardImgSrc" : "../../assets/nature2.jpg",
    "cardActionLink" : "/text-editor",
    "cardActionLinkTitle" : "Go",
    "cardTitle" : "Text Editor",
    "cardContent" : "Free online text editor. Edit, format and print, or copy and place text anywhere on the web!",
    "available" : true
  }
]


  constructor() { }

  ngOnInit() {
  }

}
