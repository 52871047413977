
import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TeMainComponent } from './te-main/te-main.component';

const textEditorRoutes: Routes = [
  {
    path: 'text-editor',
    component: TeMainComponent
  }
];
    // children: [
    //   {
    //     path: '',
    //     component: CrisisListComponent,
    //     children: [
    //       {
    //         path: ':id',
    //         component: CrisisDetailComponent
    //       },
    //       {
    //         path: '',
    //         component: CrisisCenterHomeComponent
    //       }
    //     ]
    //   }
    //  ]

@NgModule({
  imports: [
    RouterModule.forChild(textEditorRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class TextEditorRoutingModule { }