import { Component, OnInit } from '@angular/core';
declare var $:any;
declare var M:any;
import { ClipboardService  } from 'ngx-clipboard';

@Component({
  selector: 'tg-status-box',
  templateUrl: './tg-status-box.component.html',
  styleUrls: ['./tg-status-box.component.css']
})
export class TgStatusBoxComponent implements OnInit {

  title = "Text Generator";
  icon = "settings";
  body = "Not Found";  

  activeText = "Lorem Ipsum";

  statusBoxClose = false;

  texts = [`Lorem ipsum dolor, sit amet consectetur adipisicing elit. 
  Dolor fugit hic veritatis totam eaque minima consectetur velit laudantium, incidunt esse, voluptatem 
  mollitia ducimus numquam maxime explicabo vitae exercitationem? Neque, nisi?`];

  paragraphs = 1;
  numChars = this.textsLength();
  textSizeBytes = this.roughSizeOfObject(this.texts);
  formattedSize = this.formatByteSize(this.textSizeBytes);
  

  customText = "";

  constructor(private _clipboardService: ClipboardService) {   }

  ngOnInit() {
    $(document).ready(function(){
      $('.collapsible').collapsible();
      $('.collapsible').collapsible('open');
      $('.modal').modal();    

    });
  }

  /**
   * Generates/multiply current text
   */
  generateText() {
    
    this.updateActiveText();

    if(this.paragraphs <= 0){
      this.paragraphs = 1;
    } 

    if(this.customText) {
      for(let i = this.paragraphs; i > 0; i--) {
        this.texts.push(this.customText);
      }
    } else {
      
      for(let i = this.paragraphs; i > 0; i--) {
        this.texts.push(`Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor fugit hic veritatis totam eaque minima consectetur velit laudantium, incidunt esse, voluptatem mollitia ducimus numquam maxime explicabo vitae exercitationem? Neque, nisi?`);
        // this.log("In default text")
      }
    }    

    this.numChars = this.textsLength();
    this.textSizeBytes = this.sizeOfText(this.texts);
    this.formattedSize = this.formatByteSize(this.textSizeBytes);

    if(this.statusBoxClose == false){
      this.toggleStatusBox();
      $('.collapsible').collapsible('close');
    }  

  }

  /**
   * Clears a number of variable, reset app state
   */
  reset() {
    this.texts = [];
    this.paragraphs = 1;
    this.numChars = 0;
    this.textSizeBytes = 0;

    if(this.statusBoxClose == true){
      this.toggleStatusBox();
      $('.collapsible').collapsible('open');
    }

  }  

  toggleStatusBox () {

    if(this.statusBoxClose == false) {
      this.statusBoxClose = true;
      
    } else if (this.statusBoxClose == true) {
      this.statusBoxClose = false;
    }

  }

  /**
   * returns length of array strings 
   */
  textsLength(){
    let textLength = 0;

    for(let i = 0; i < this.texts.length; i++) {
      textLength += this.texts[i].length;
    }

    return textLength;
  }

  /**
   * Logs a message to console
   * @param msg any object to log to console
   */
  log(msg) {
    console.log(msg);
  }

  /**
   * Updates which text is currently in use, custom or default 
   */
  updateActiveText() {
    if(this.customText) {
      this.activeText = "Custom Text";
    } else {
      this.activeText = "Lorem Ipsum";
    }
  }

  /**
   * Clears the customText variable
   */
  clearCustomText() {
    this.customText = "";
  }

  /**
   * https://gist.github.com/zensh/4975495
   * Get the memory size of an object
   * @param obj any object
   */
  memorySizeOf(obj) {
    var bytes = 0;

    function sizeOf(obj) {
        if(obj !== null && obj !== undefined) {
            switch(typeof obj) {
            case 'number':
                bytes += 8;
                break;
            case 'string':
                bytes += obj.length * 2;
                break;
            case 'boolean':
                bytes += 4;
                break;
            case 'object':
                var objClass = Object.prototype.toString.call(obj).slice(8, -1);
                if(objClass === 'Object' || objClass === 'Array') {
                    for(var key in obj) {
                        if(!obj.hasOwnProperty(key)) continue;
                        sizeOf(obj[key]);
                    }
                } else bytes += obj.toString().length * 2;
                break;
            }
        }        

        return bytes/2;
    };

    

    function formatByteSize(bytes) {
        if(bytes < 1024) return bytes + " bytes";
        else if(bytes < 1048576) return(bytes / 1024).toFixed(3) + " KB";
        else if(bytes < 1073741824) return(bytes / 1048576).toFixed(3) + " MB";
        else return(bytes / 1073741824).toFixed(3) + " GB";        
    };

    return formatByteSize(sizeOf(obj));
};

/**
 * This is a duplicate 
 * @param obj 
 */
sizeOfText(obj) {

var bytes = 0;
var char = 'c';

  for(let i = 0; i < obj.length; i++){
    bytes += obj[i].length;
  }
 
  return bytes;
};

roughSizeOfObject( object ) {

  var objectList = [];
  var stack = [ object ];
  var bytes = 0;

  while ( stack.length ) {
      var value = stack.pop();

      if ( typeof value === 'boolean' ) {
          bytes += 4;
      }
      else if ( typeof value === 'string' ) {
          bytes += value.length * 2;
      }
      else if ( typeof value === 'number' ) {
          bytes += 8;
      }
      else if
      (
          typeof value === 'object'
          && objectList.indexOf( value ) === -1
      )
      {
          objectList.push( value );

          for( var i in value ) {
              stack.push( value[ i ] );
          }
      }
  }
  // this.log(this.formatByteSize(bytes));
  return bytes;
}

formatByteSize(bytes) {
  if(bytes < 1024) return bytes + " bytes";
  else if(bytes < 1048576) return(bytes / 1024).toFixed(3) + " KB";
  else if(bytes < 1073741824) return(bytes / 1048576).toFixed(3) + " MB";
  else return(bytes / 1073741824).toFixed(3) + " GB";        
};

/**
 * Copy current text in texts array to clipboard
 */
copy() {

  if(this.texts.length == 0) {
    this.toast("Nothing to copy");
    return;
  }
  //https://github.com/maxisam/ngx-clipboard
  this._clipboardService.copyFromContent(this.texts.join('\n'));

  this.toast("Text copied to clipboard!");
}

/**
 * Post a message to user's screen via materialize toast
 * @param msg message to post via toast notification
 */
toast (msg) {
  M.toast({html: msg});
}
}
