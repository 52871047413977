import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomepageRoutingModule } from './homepage-routing.module';
import { HpMainComponent } from './hp-main/hp-main.component';
import { CardHolderComponent } from '../components/card-holder/card-holder.component';
import { ImageCardComponent } from '../components/image-card/image-card.component';

@NgModule({
  imports: [
    CommonModule,
    HomepageRoutingModule    
  ],
  declarations: [
    HpMainComponent, 
    CardHolderComponent,
    ImageCardComponent
  ],
  exports: [HpMainComponent]
})
export class HomepageModule { }
