import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TGMainComponent } from './main/tg-main.component';
import { TextGeneratorRoutingModule } from './text-generator-routing.module';
import { CollapsibleComponent } from '../components/collapsible/collapsible.component';
import { TgStatusBoxComponent } from './tg-status-box/tg-status-box.component';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
  imports: [
    CommonModule,
    TextGeneratorRoutingModule,
    FormsModule,
    ClipboardModule
  ],
  declarations: [
    TGMainComponent,
    CollapsibleComponent,
    TgStatusBoxComponent
  ],
  exports: [TGMainComponent]
})
export class TextGeneratorModule {

  

 }
